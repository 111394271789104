/* Style the header with a grey background and some padding */
.header {
    overflow: hidden;
    background-color: transparent;
    padding: 20px 10px;
  }
  
  /* Style the header links */
  .header a {
    float: left;
    color: lightgrey;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
  }
  
  /* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
  .header a.logo {
    font-size: 25px;
    font-weight: bold;
  }

  .header .logo {
    font-size: 25px;
    font-weight: bold;
  }
  
  /* Change the background color on mouse-over */
  .header a:hover {
    background-color: rgba(229, 228, 226, .15);
    color: white;
  }
  
  /* Style the active/current link*/
  .header a.active {
    background-color: #4a83c9;
    color: white;
  }

  .addventure {
    font-weight: bold;
    font-size: 40;
  }

  .addOrange {
    color: #f36c21;
  }

  .addGrey {
    color: lightgrey;
  }
  
  /* Float the link section to the right */
  .header-right {
    float: right;
  }

  .header-left {
    float: left;
  }
  
  /* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
  @media screen and (max-width: 500px) {
    .header a {
      float: none;
      display: block;
      text-align: left;
    }
    .header-right {
      float: none;
    }
  }