body {
  font-family: sans-serif;
  background: whitesmoke;
}
  
form {
  margin: 3rem auto 0;
  width: 24rem;
  
  div {
    width: inherit;
    margin-bottom: 1rem;
  }

  label {
    display: block;
    color:black;
  }

  fieldset {
    margin-bottom: 2rem;
    border-radius: 4px;
    border-color: #555;
  }

  
  input,
  select,
  textarea {
    background: lightgray;
    margin: 0.5rem 0;
    padding: 0.5rem;
    width: inherit;
    height: 2rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type="submit"] {
    height: 2.5rem;
    font-size: 1rem;
    color: black;
    background: #f36c21;
    cursor: pointer;
  }

  input[type="reset"] {
    height: 2.5rem;
    font-size: 1rem;
    color: white;
    background: #4a83c9;
    cursor: pointer;
  }

  input[type="text"] {
    height: 2.5rem;
    width: 100%;
    font-size: 1rem;
    color: black;
    background: lightgray;
    cursor: pointer;
    border-radius: 0px;
  }
  
  textarea {
    height: 5rem;
  }
}


  