// main {
//     padding-left: 20px;
//     padding-right: 20px;
// }

button {
    color: white;
    background-color: #2596be;
    width: 130px;
    height: 30px;
    display: inline;
    font-size: medium;
    border-width: 0px;
    flex-direction: row;
    font-size: 11px;
    border-radius: 5px;
    
}

.separatedRow {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.topRow {
    background-color: #f36c21;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding-top: 20px;
    padding-left: 20px;
}

.centerText {
    text-align: center;
    padding-top: 40px;
}