.panel {
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin: 10px;
}

.panelTitle {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding-top: 8px;
    color: #f36c21;
}

.panelText {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    height: 100px;
}

.separatedRow {
    display: flex;
    justify-content: space-between;
}

.padLeftRight {
    padding-left: 20px;
    padding-right: 20px;
}

.formCard{
    margin-top: 20px;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.editUserForm {
    padding-left: 20px;
    padding-right: 20px;
    width: 90%;
}