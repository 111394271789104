.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:55vh;  
}

#Username,
#Password,
.loginInput {
    padding: 10px;
    margin:10px 0px;
    border-radius:10px;
    margin-left: auto;
    margin-right: auto;
    background-color: gainsboro;
    font-weight: bold;
    width: 400px;
    height: 50px;
    align-items: center;
    display: flex;
}

::placeholder {
    color: black;
    font-style: italic;
 }

 .logoImage {
    width: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
 }

 .loginButton {
    width: 200px;
    border-radius:20px;
    background:#f36c21;
    border: 0px;
    padding: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    color: black;
    font-weight: bold;
    font-size: 16px;
 }

 .forgotButton {
     background: #4a83c9;
     color: white;
 }