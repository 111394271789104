main {
    position: relative;
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
}

.container {
    display:flex;
}

.dynamo-form {
    margin: 0;
    padding: 0;
    width: 100%;
}


.styled-table {
    border-collapse: collapse;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 95%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #2596be22;
    color: #f36c21;
    text-align: left;
    font-size: 18px;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #aaa;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f36c2111;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #f36c21;
}

.dateInput {
    background-color: #fff;
}

.padLeft {
    margin-left: 10px;
}

.submit-button {
    width: 170px;
    font-weight: 600;
}